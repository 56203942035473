import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Stage, UserModel } from '../../shared/services/forge-rock.interface';
import { AbstractJourneyComponent } from '../abstract-journey/abstract-journey.component';

@Component({
  selector: 'journey-login',
  templateUrl: './journey-login.component.html'
})
export class JourneyLoginComponent  extends AbstractJourneyComponent implements OnInit, AfterViewInit, OnDestroy{
  @Input() otpInstruction !: string;
  @Input() buttonText : string = 'Submit';
  @Input() oneTimeButtonText : string = 'Submit';


  @ViewChild('loginForm', { static: true }) ngForm !: NgForm;
  @ViewChild('ErrModel') errModel !: NgModel;
  @ViewChild('subButton') subButton !: ElementRef;

  loginbuttonText : string = 'Submit';
  protected submitted = false;

  protected err !: any;
  protected errorCode !: any;
  protected unknownErrorMessage = "Unknown Error";
  protected errorMessage !: string;
  protected monitorChanges = false;
  protected showSpinner = false;
  private subscription !: Subscription;
  private formSubscription !: Subscription;
  private  sentVerificattionCode = false;
 
  override getTreeName(): string {
    return 'login';
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.loginbuttonText = this.buttonText;
  }

  override ngAfterViewInit(): void {
    super.ngAfterViewInit();
 
    let subscribe = this.OnJourneyStarted.subscribe((val)=>{
      if (val) {
          this.handleLoginFailures();
          this.redirectIfUpdateTouStage();
          subscribe.unsubscribe();
      }
     });
  }

  ngOnDestroy() : void {
    this.subscription?.unsubscribe();
  }

  onSubmit() {
      this.showErrors();
      if(this.ngForm.invalid) {
        return;
      }
      let formdata = this.ngForm.form.value;
      let credentials : UserModel = {} as UserModel;
      credentials.username = formdata.username;
      credentials.password = formdata.passwords?.password || formdata.onetimePassword;
      credentials.otpChoice = this.otpChoice;

      this.handleResendCode('reject').then((resp) => {
        this.submitted = true
        this.contineJournery(credentials).then((nextStage)=>{
          this.submitted = false;
          if (this.sentVerificattionCode) {
            this.confirmSuccess(this.sentVerificattionCode);
            this.sentVerificattionCode = false;
          }
          this.redirectIfUpdateTouStage();
        },(err)=>{
          this.submitted = false;
         });
      });
  }

  private contineJournery(credentials:any) {
    return this.nextStageInJourney(credentials).then((nextStage)=>{
      if (this.nextStage == Stage.EMAIL_SENT) {
        this.sentVerificattionCode = true;
        this.contineJournery(credentials);
      }
    });
  }

  protected setOtpChoice(choiceResp:any) {
    this.otpChoice = choiceResp;
    this.ngForm.ngSubmit.emit();
  }

  protected getParentUrl(){
    return window.location.href;
  }

  protected handleLoginFailures() {
    this.subscription = this.FRService.OnLoginFailure.subscribe((err:any)=>{
      this.errorCode = err && err.code ? err.code : undefined;
      this.errorMessage = this.errorCode && err.message ? err.message : environment.common.errorMessages.loginFailureUnknown; 
      if (this.errorCode) {
          this.monitorChanges = true;
          this.formSubscription = this.ngForm.form.valueChanges.subscribe((val:any)=>{
            if (val && !this.monitorChanges) {
              this.formSubscription.unsubscribe();
              this.errorCode = undefined;
            }
            this.monitorChanges = false;
          });
      }
    });
  }

  private redirectIfUpdateTouStage() {
    if (this.nextStage == Stage.TOU)  this.updateTou();
  }
}
