import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserModel } from '../../shared/services/forge-rock.interface';
import { ForgeRockService } from '../../shared/services/forge-rock.service';
import { UserService } from '../../shared/services/user.service';
import { AbstractJourneyComponent } from '../abstract-journey/abstract-journey.component';

@Component({
  selector: 'journey-update-tou',
  templateUrl: './journey-update-tou.component.html',
})
export class JourneyUpdateTouComponent extends AbstractJourneyComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() buttonText: string = 'Reset Password';
  @Input() otpButtonText: string = 'Continue';
  @Input() otpInstruction!: string;
  @Input() headerText!: string;
  @Input() resetWarning!: string;
  step = undefined;

  @Output() onTouUpdated = new EventEmitter<any>();

  showSpinner = false;
  private subscriptions: Subscription[] = [];
  protected submitted = false;

  protected err!: any;
  protected errorCode!: any;
  protected unknownErrorMessage = 'Unknown Error';
  protected errorMessage!: string;
  protected monitorChanges = false; 

  constructor(
    protected override router: Router,
    protected override route: ActivatedRoute,
    protected override FRService: ForgeRockService,
    protected override userService: UserService
  ) {
    super(FRService, router, route, userService);
  }

  override getTreeName(): string {
    return 'updateTOU';
  }

  override ngOnInit(): void {
      super.ngOnInit();
      this.skipStartOfJourney = true;
  }
  override ngAfterViewInit():void {
    super.ngAfterViewInit(); 
    const profileItem = this.route.snapshot.params['item'];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s?.unsubscribe);
  }

  onStepComplete(data?: any) {
    let userCredentials: UserModel = {} as UserModel;
    userCredentials.touAccepted = this.userService.touAccepted;
    this.submitted = true;
    return this.nextStageInJourney(userCredentials).then(
      (nextStage) => {
        this.submitted = false;
        return nextStage;
      },
      (err) => {
        this.submitted = false;
      }
    );
  }
}
