<dn-progress-spinner *ngIf="showSpinner"></dn-progress-spinner>
<div class="col-lg-11 col-md-11 col-sm-12 shadow-lg rounded mt-5 ms-md-5 mb-5">
		<div class="d-flex p-4">
        <terms-of-use
          [touContent]="'tou.content' | translate"
          [touCheckBoxName]="'tou.checkBoxName' | translate"
          (touAcceptedEvent)="onStepComplete($event)" />
		</div>
</div>

