<div class="d-flex justify-content-center">
    <div class="col-lg-4 col-md-5 col-sm-12 shadow-lg rounded pb-5 bg-white mt-5 px-3" *ngIf="!redirecting && profType !== ProfileType.TOU">
        <div class="text-primary">
            <h5 class="mt-3 d-flex justify-content-center pt-5">
                <i [ngClass]="icon"></i><br>
            </h5>
            <h5 class="d-flex justify-content-center">
            <p *ngIf="headerText">{{headerText}}</p>
        </h5>
        </div>
        <journey-change-email   *ngIf="profType == ProfileType.EMAIL"
            instructions="{{'changeEmail.instruction' | translate}}"
            autofocus="true"
            (onProfileChanged)="onStepComplete($event)">
        </journey-change-email>

        <journey-change-password  *ngIf="profType == ProfileType.PASSWORD"
            (onPasswordChanged)="onStepComplete($event)"> 
        </journey-change-password>
    </div>

    <div *ngIf="profType == ProfileType.TOU">
          <journey-update-tou>
                (onProfileChanged)="onStepComplete($event)">
          </journey-update-tou>
    </div>

    <div class="" *ngIf="redirecting && profType !== ProfileType.TOU">
        <confirm-email
            [data]="translationData"
            [confirmEmailContent]="successContent"
            confirmEmailTitle=""
            [headerText]="successHeaderText"
            [updateTranslation]="redirecting">
        </confirm-email>
    </div>
</div>